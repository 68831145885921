
// @ts-nocheck
export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

export const pageview = () => {
  window.fbq('track', 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  window.fbq('track', name, options)
}

export const eventSingle = (name, fbPixel, options = {}) => {
  window.fbq('trackSingle', fbPixel, name);
}

export const initPixel = (pixelId: string) => {
  window.fbq('init', pixelId);
}