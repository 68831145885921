import { ICampaign, IRetailer } from '../models/Campaign';
import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import { StateOptions } from './constants';

export function validateEmail(email: string) {
  const trimmedEmail = email.trim().toLowerCase();
  const emailReg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email.trim().toLowerCase().endsWith('.con')) {
    return null;
  } else if (emailReg.test(trimmedEmail)) {
    return trimmedEmail;
  } else {
    return null;
  }
}
export function captureException<T>(
  err: T,
  options?: Parameters<typeof Sentry.captureException>[1]
): void {
  Sentry.captureException(err, options);
}

export function handleEnterKeySubmit(event: any, submitButtonRef: any) {
  const keyPressed = event.keyCode || event.which;
  if (keyPressed === 'Enter') {
    submitButtonRef.current.click();
  }
}

export function generate9DigitTimestamp() {
  // Get the current timestamp in seconds (not milliseconds)
  const currentTimestamp = Math.floor(new Date().getTime() / 1000);

  // Convert the timestamp to a string and take the last 9 digits
  const timestampString = currentTimestamp.toString().slice(-9);

  return timestampString;
}

export function campaignIsActive(type: number, campaignEndDate: string, remainingRedemptions: number) {
  if (remainingRedemptions > 0 && type === 0 && campaignEndDate && new Date(campaignEndDate) > new Date(Date.now())) {
    return true;
  } else {
    return false;
  }
}

export function getFollowupCode() {
  const newCode = Math.floor(1000 + Math.random() * 9000);
  return newCode;
}

export function getRegions(DMA: string[]) {
  const regions = DMA.map((region, index) => {
    return `${region}${index === DMA.length - 1 ? '' : ','}`;
  });
  return regions;
}

export function getReferralLink(
  baseUrl: string,
  campaignId: number,
  restrictByReferrer: boolean,
  campaignReferrerString: string,
  specificRetailer: string | undefined
) {
  //note that the userId of the referring user is appended to the referralLink in Landbot (CreateRebate)
  let referralLink = new URL(`${baseUrl}/campaigns/${campaignId}`);
  if (restrictByReferrer) {
    referralLink.searchParams.append('utm_campaign', `${campaignReferrerString}`);
  }
  if (specificRetailer) {
    referralLink.searchParams.append('r', `${specificRetailer}`);
  }
  return referralLink.toString();
}

export function appendStoreLocatorComponent(brandRetailLocationsScript: string) {
  console.log(`appendStoreLocator: ${brandRetailLocationsScript}`);
  if (brandRetailLocationsScript.includes('lets.shop')) {
    const splashNavMenuEmbed = document.getElementById('splash-nav-menu-embed');
    if (!splashNavMenuEmbed) return;

    const iframe = document.createElement('iframe');
    iframe.id = 'destini';
    iframe.src = brandRetailLocationsScript;
    iframe.allow = 'geolocation';
    iframe.textContent = 'Your browser does not support iframes.';
    iframe.width = '100%';
    iframe.style.borderRadius = '0.25rem';
    iframe.style.borderWidth = '0px';

    splashNavMenuEmbed.appendChild(iframe);
  } else if (brandRetailLocationsScript.includes('stockist')) {
    const widgetTag = brandRetailLocationsScript.split(':')[1];

    const splashNavMenuEmbed = document.getElementById('splash-nav-menu-embed');
    if (!splashNavMenuEmbed) return;

    const div = document.createElement('div');
    div.setAttribute('data-stockist-widget-tag', widgetTag);
    const anchor = document.createElement('a');
    anchor.href = 'https://stockist.co';
    div.appendChild(anchor);

    const script = document.createElement('script');
    script.textContent = `(function(s,t,o,c,k){c=s.createElement(t);c.src=o;c.async=1;
      k=s.getElementsByTagName(t)[0];k.parentNode.insertBefore(c,k);
    })(document,'script','//stockist.co/embed/v1/widget.min.js');`;

    splashNavMenuEmbed.appendChild(div);
    splashNavMenuEmbed.appendChild(script);
  } else if (brandRetailLocationsScript.includes('google.com')) {
    const splashNavMenuEmbed = document.getElementById('splash-nav-menu-embed');
    if (!splashNavMenuEmbed) return;

    const iframe = document.createElement('iframe');
    iframe.id = 'googlemaps';
    iframe.width = '100%';
    iframe.style.borderRadius = '0.25rem';
    iframe.style.borderWidth = '0px';
    iframe.src = brandRetailLocationsScript;
    iframe.allow = 'geolocation';
    iframe.textContent = 'Your browser does not support iframes.';
    if (!document.getElementById('googlemaps')) splashNavMenuEmbed.appendChild(iframe);
  } else {
    const splashNavMenuEmbed = document.getElementById('splash-nav-menu-embed');
    if (!splashNavMenuEmbed) return;

    const iframe = document.createElement('iframe');
    iframe.id = 'generic_store_locator';
    iframe.width = '100%';
    iframe.style.borderRadius = '0.25rem';
    iframe.style.borderWidth = '0px';
    iframe.allow = 'geolocation';
    iframe.textContent = 'Your browser does not support iframes.';
    if (document.getElementById('generic_store_locator')) return;

    splashNavMenuEmbed.appendChild(iframe);

    const iframeDocument = iframe?.contentWindow?.document;
    iframeDocument?.open();
    iframeDocument?.write(brandRetailLocationsScript);
    iframeDocument?.close();
  }
}

export function updateStyle(buttonColor: string, buttonTextColor: string, chatConfigId: string) {
  const campaignInactiveConfigUrl = 'H-1350544-HET23ACZ0RGS4TJ4';
  const newStyle = `
    button {
      background-color: ${buttonColor} !important;
      color: ${buttonTextColor} !important;
    }

    .lb-loader {
      color: ${buttonColor} !important;
    }

    .MessageBubble.is-author-user {
      background-color: ${buttonColor} !important;
      color: ${buttonTextColor} !important;
    }

    .MessageBubble.is-author-user:first-child .MessageBubble__Decorator path {
      fill: ${buttonColor} !important;
    }

    .input-button.is-multiple {
      color: rgb(20, 20, 20) !important;
      background-color: rgb(250, 250, 250) !important;
      border-color: transparent !important;
    }

    .input-button.is-multiple.is-selected {
      background-color: ${buttonColor} !important;
      color: ${buttonTextColor} !important;
    }
  `;

  if (!chatConfigId.includes(`${campaignInactiveConfigUrl}`)) {
    window.changeCSS(newStyle);
  }

  return newStyle;
}

function createMaintenanceOverlay() {
  const maintenanceOverlay = document.createElement('div');
  maintenanceOverlay.setAttribute('id', 'maintenance-overlay');
  maintenanceOverlay.setAttribute('class', 'maintenance-alert');

  // Position the overlay
  maintenanceOverlay.style.position = 'fixed'; // or 'absolute' if you prefer
  maintenanceOverlay.style.top = '50%';
  maintenanceOverlay.style.left = '50%';
  maintenanceOverlay.style.transform = 'translate(-50%, -50%)';
  maintenanceOverlay.style.zIndex = '9999'; // Ensure it's above other content
  maintenanceOverlay.style.backgroundColor = 'white'; // Optional: add background
  maintenanceOverlay.style.padding = '2rem'; // Optional: add some spacing
  maintenanceOverlay.style.borderRadius = '5px'; // Optional: rounded corners
  maintenanceOverlay.style.boxShadow = '0 2px 10px rgba(0,0,0,0.1)'; // Optional: add shadow
  maintenanceOverlay.style.textAlign = 'center'; // Center all text content
  maintenanceOverlay.style.minWidth = '300px';

  const line1 = document.createElement('div');
  line1.textContent = '⚙️🔧';
  line1.style.fontSize = '2rem';
  line1.style.display = 'flex';
  line1.style.justifyContent = 'center';
  line1.style.marginBottom = '1rem'; // Add space between emoji and text

  const line2 = document.createElement('div');
  line2.innerHTML = 'Hey friend! Please check back in a bit, we&#x27;re working on a few things!';
  line2.style.fontSize = '1.2rem';
  line2.style.display = 'flex';
  line2.style.justifyContent = 'center';

  maintenanceOverlay.appendChild(line1);
  maintenanceOverlay.appendChild(line2);

  return maintenanceOverlay;
}

export function showMaintenanceOverlay() {
  const body = document.querySelector('body');
  const maintenanceOverlay = createMaintenanceOverlay();
  const showOverlay = body?.appendChild(maintenanceOverlay);
  return showOverlay;
}

export function getRetailersUnorderedList(campaignData: ICampaign, filteredRetailers: IRetailer[]) {
  const retailersJson = filteredRetailers;
  let outputString;
  let allRetailers = false;
  if (retailersJson !== null) {
    outputString = '<ul>';
    for (var i = 0; i < retailersJson.length; i++) {
      let retailerUrl = retailersJson[i].Url;
      if (retailersJson[i].Id == 'ALL') {
        outputString = `<li><a href="${campaignData?.BrandRetailLocationsUrl}">All ${campaignData.BrandName} Retailers (in-store & online via in-store/curbside pickup)</a></li>`;
        allRetailers = true;
      } else if (allRetailers == false && retailersJson[i].Id !== 'FRDR') {
        outputString += "<li><a href='" + retailersJson[i].Url + "'>" + retailersJson[i].Name + '</a></li>';
      } else {
        let encodedBrand = campaignData.BrandName.replace(' ', '%20');
        retailerUrl = retailerUrl.replace('brandname', encodedBrand);
        outputString += "<li><a href='" + retailerUrl + "'>" + retailersJson[i].Name + '</a></li>';
      }
    }
    outputString += '</ul>';
  }
  return outputString;
}

export async function createHubspotTicket(params: TicketParams) {
  if (process.env.NEXT_PUBLIC_ENV !== 'production') return;
  try {
    await axios.post('/api/hubspot', params);
    return 'Hubspot Ticket Submitted!';
  } catch (err) {
    captureException(err);
  }
}

export const getFormattedDate = (timestamp: string): string => {
  if (!timestamp) {
    return '-';
  }
  const datePart = timestamp.substring(0, 10);

  return datePart;
};

export function findStateIdByName(stateName: string) {
  const result = StateOptions.find((state) => state.name === stateName);
  return result ? result.id : null;
}

interface TicketParams {
  type: string;
  hs_pipeline: string;
  hs_pipeline_stage: string;
  subject: string;
  content: string;
  email: string;
}
