import { useEffect, useState } from 'react';

export enum ScrollDirection {
  up = "up",
  down = "down",
}

export const useScrollDirection = () => {
  const threshold = 50;
  const [scrollDir, setScrollDir] = useState(ScrollDirection.up);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      //console.log("window is defined");
      let previousScrollYPosition = window.scrollY;

      const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
        Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;

      const isScrollingUp = (currentScrollYPosition: number) =>
        currentScrollYPosition > previousScrollYPosition &&
        !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
        !(currentScrollYPosition > 0 && previousScrollYPosition === 0);

      const updateScrollDirection = () => {
        const currentScrollYPosition = window.scrollY;

        if (scrolledMoreThanThreshold(currentScrollYPosition)) {
          const newScrollDirection = isScrollingUp(currentScrollYPosition)
            ? ScrollDirection.down
            : ScrollDirection.up;
          setScrollDir(newScrollDirection);
          previousScrollYPosition =
            currentScrollYPosition > 0 ? currentScrollYPosition : 0;
        }
      };

      const onScroll = () => {
        //console.log('onScroll fired');
        window.requestAnimationFrame(updateScrollDirection);
      };

      window.addEventListener("scroll", onScroll);

      return () => window.removeEventListener("scroll", onScroll);
    }
    console.log("window is undefined");
  }, []);
  //console.log(scrollDir);
  return scrollDir;
};

export default useScrollDirection;
