import Router from 'next/router';
import SessionReact from 'supertokens-auth-react/recipe/session';
import ThirdPartyPasswordlessReact from 'supertokens-auth-react/recipe/thirdpartypasswordless';

import { ThirdPartyPasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/thirdpartypasswordless/prebuiltui';
import { righteous } from '../utils/fonts';
import { appInfo } from './appInfo';

export const frontendConfig = () => {
  return {
    appInfo,
    recipeList: [
      ThirdPartyPasswordlessReact.init({
        style: `
        [data-supertokens~=superTokensBranding] {
            display: none;
        }

        [data-supertokens~=container] {
          box-shadow: 1px 1px 10px rgba(89, 126, 247, 0.5);
          background-image: url('https://uploads-ssl.webflow.com/5be3a787a9b14f88f88bfce4/606c9025d3502d5cd019db51_sampoll-logo-21.svg');
          background-size: 90px; /* Adjust the size as needed */
          background-repeat: no-repeat;
          background-position: center top 20px; /* Adjust the position and margin-top */
      }

        [data-supertokens~=label]{
          font-family: ${righteous.style.fontFamily};
          font-size: 17px;
        }

        [data-supertokens~=thirdPartyPasswordlessDividerText]{
          font-family: ${righteous.style.fontFamily};
        }

        [data-supertokens~=container]{
          box-shadow: 1px 1px 10px rgba(89,126,247, 0.5);
        }

        [data-supertokens~=button]{
          background-color:#597ef7;
          border-color:#597ef7;
          font: 14px ${righteous.style.fontFamily};
        }

        [data-supertokens~=providerButton]:hover{
          background-color:#597ef7;
        }

        [data-supertokens~=providerButtonText] {
          color: white;
        }

        [data-supertokens~=input]:focus {
          border-color:#597ef7;
        }

        [data-supertokens~=inputWrapper]:focus-within {
          border: 1px solid #597ef7;
          box-shadow: 0 0 0 0.2rem rgba(89,126,247, 0.25);
          outline: none;
        }

        [data-supertokens~=divider]{
          margin-top: 3em;
        }

        [data-supertokens~=headerTitle]{
          visibility: hidden;
          position: relative;
          font-family: ${righteous.style.fontFamily};
          font-size: 20px;
          line-height: 27px;
        } 

        [data-supertokens~=headerTitle]:after {
          visibility: visible;
          position: absolute;
          font-size: 10px
          padding-top: 20px;
          top: 20px;
          left: 0;
          content: "Enter you email below to check out our latest featured offers!";
        }

        [data-supertokens~=secondaryText] {
          padding-top: 40px;
        }

    `,
        signInUpFeature: {
          // providers: [ThirdPartyPasswordlessReact.Google.init()],
        },
        contactMethod: 'EMAIL',
        useShadowDom: false,
      }),
      SessionReact.init(),
    ],
    windowHandler: (oI: any) => {
      return {
        ...oI,
        location: {
          ...oI.location,
          setHref: (href: string) => {
            Router.push(href);
          },
        },
      };
    },
  };
};

export const PreBuiltUIList = [ThirdPartyPasswordlessPreBuiltUI];
